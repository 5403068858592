(($) => {
    $(document).on('submit', '#contactForm', (e) => {
        e.preventDefault();

        let form = $(e.target);

        let element = document.getElementById('contactModal');
        let modal = bootstrap.Modal.getInstance(element);

        modal.hide();

        let elementModalBody = element.querySelector('.modal-body');

        $.ajax({
            url: '//belenvasquez.com/forms/submission/b3564a69-ced7-4b85-b4fe-14dd4e6e5d16.json',
            method: 'post',
            data: form.serialize(),
            beforeSend: () => {
                Swal.fire({
                    title: 'Tu mensaje se está procesando.',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                })
            },
        }).done((data) => {
            if (data.success) {
                elementModalBody.textContent = 'Se envió el mensaje, pronto me contactaré contigo.';
                Swal.fire('El mensaje se ha enviado con éxito.', 'Muy pronto te responderé.', 'success');
            }
            else {
                Swal.fire('Ha ocurrido un error', 'Se ha producido un error al intentar enviar el mensaje, inténtalo mas tarde.', 'error').then((result) => {
                    modal.show();
                });
            }
        }).fail(() => {
            Swal.fire('Ha ocurrido un error', 'Se ha producido un error al intentar enviar el mensaje, inténtalo mas tarde.', 'error').then((result) => {
                modal.show();
            });
        });
    })
})(jQuery);
